import type {LoaderFunctionArgs} from "@remix-run/node";
import {json, redirect} from "@remix-run/node";
import {Form, useLoaderData} from "@remix-run/react";

import {login} from "~/shopify.server";

import styles from "./styles.module.css";
import {ROUTES} from "~/routes/routes.list";

export const loader = async ({request}: LoaderFunctionArgs) => {
  const url = new URL(request.url);

  if (url.searchParams.get("shop")) {
    throw redirect(`${ROUTES.APP}?${url.searchParams.toString()}`);
  }

  return json({showForm: Boolean(login)});
};

export default function App() {
  const {showForm} = useLoaderData<typeof loader>();

  return (
    <div className={styles.index}>
      <div className={styles.content}>
        <h1 className={styles.heading}>A short heading about [your app]</h1>
        <p className={styles.text}>
          A tagline about [your app] that describes your value proposition.
        </p>
        {showForm && (
          <Form className={styles.form} method="post" action={ROUTES.LOGIN}>
            <label className={styles.label}>
              <span>Shop domain</span>
              <input className={styles.input} type="text" name="shop"/>
              <span>e.g: my-shop-domain.myshopify.com</span>
            </label>
            <button className={styles.button} type="submit">
              Log in
            </button>
          </Form>
        )}
      </div>
    </div>
  );
}
